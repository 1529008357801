const translateRole =(value)=>{
    const roleTranslations = {
        "root": "Administrador",
        "teacher": "Tutor",
        "operational_direction": "Dirección Operativa",
        "rrhh": "Recursos Humanos",
        "executive": "Ejecutivo de A&D",
        "portfolio": "Ejecutivo de recaudo",
        "support": "Soporte académico",
        "student": "Estudiante"
    };

    return roleTranslations[value] || "Valor no encontrado";
}

export default translateRole;